import { PayloadAction } from "@reduxjs/toolkit";

import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { IOrderEntryHeaderInfo } from "../Models/OrderEntry/IOrderEntryHeaderInfo";

export const slice = createGenericSlice({
  name: "orderEntryHeaderInfo",
  initialState: {
    status: "loading",
    data: {
      isGuest: true,
      delivery: {
        delivery: false,
        postCode: "",
        deliveryCharge: 0,
        minimumOrder: 0,
      },
      customerId: "",
      customerName: "",
      addressId: "",
      address: "",
    },
  } as GenericState<IOrderEntryHeaderInfo>,
  reducers: {
    setHeaderInfo: (state, action: PayloadAction<IOrderEntryHeaderInfo>) => {
      state.status = "finished";
      state.data = action.payload;
    },
  },
});

export const selectHeaderInfo = (state: {
  orderEntryHeaderInfo: { data: IOrderEntryHeaderInfo };
}) => state.orderEntryHeaderInfo.data;

export const { setHeaderInfo } = slice.actions;

export default slice.reducer;
