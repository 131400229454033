import JwtDecode from "jwt-decode";
import { IUser, IJwt, IUserSession } from "../Models/user";
import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { PayloadAction } from "@reduxjs/toolkit";
import { getUser } from "../Services/common";

export const slice = createGenericSlice({
  name: "user",
  initialState: {
    status: "loading",
    data: getUser(),
  } as GenericState<IUser>,
  reducers: {
    setToken: (state, action: PayloadAction<IUser>) => {
      state.status = "loading";
      if (action.payload.token.length > 0) {
        action.payload.roles = setRoles(action.payload.token);

        state.data = action.payload;

        if (state.data) {
          setUser(state.data);
        }
      } else window.localStorage.removeItem("account");

      state.status = "finished";
    },
    refreshToken: (state, action) => {
      state.status = "finished";
      state.data = action.payload;
    },
  },
});
export const selectUser = (state: { user: { data: IUser } }) => state.user.data;
export const isGuest = (state: { user: { data: IUser } }) =>
  state.user.data.roles.indexOf("Guest") > -1;
export const { setToken, refreshToken } = slice.actions;

export default slice.reducer;
function setRoles(token: string | null) {
  if (token === null) {
    return [];
  }
  const tokenDecoded: IJwt = JwtDecode(token);
  if (tokenDecoded && tokenDecoded.role) {
    const roles: string[] = tokenDecoded.role;
    return roles;
  } else return [];
}

function setUser(value: IUser, expirationInDay = 10) {
  window.localStorage.removeItem("account");
  let expirationDate = new Date(
    new Date().getTime() + 60000 * expirationInDay * 60 * 24
  );
  let newValue: IUserSession = {
    user: value,
    date: expirationDate,
  };
  window.localStorage.setItem("account", JSON.stringify(newValue));
}
