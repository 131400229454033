import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const FreebiesProductsStyles = makeStyles((theme: Theme) =>
  createStyles({
    freebiesRoot: {
      backgroundColor: "rgb(247, 216, 0)",
      padding: "0 0.5rem",
    },
    categoryTitle: {
      marginBottom: "10px !important",
      display: "inline-block",
      padding: "5px 10px",
      // color: theme.palette.text.primary,
      color: "#222",
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
    description: {
      // color: theme.palette.text.primary,
      color: "#222",
      padding: "0 10px 10px 0",
    },
    foods: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginTop: "0.5rem !important",
    },
  })
);
