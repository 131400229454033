import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { IProductAttributeValue } from "../../../Models/productAttributeValue";
import { selectMoney } from "../../../Stores/informationSlice";

import { Radio2Style } from "./Radio2Style";

interface IProps {
  productAttributeValue: IProductAttributeValue;
  attributeName: string;
  optionId: string;
  handleRadioButon(
    value: IProductAttributeValue,
    optionDisplayOrder: number,
    attributeType: string
  ): boolean;
  attributeType: string,
  isInCart: boolean;
  optionDisplayOrder: number;
}
const Radio2: React.FC<IProps> = ({
  productAttributeValue,
  attributeName,
  optionId,
  handleRadioButon,
  attributeType,
  isInCart,
  optionDisplayOrder,
}) => {
  const money = useSelector(selectMoney);
  const classes = Radio2Style();
  return (
    <label className={classes.Radio2Style + " radioButton"}>
      {`${productAttributeValue.name} ${productAttributeValue.priceAdjustment > 0
        ? `(${money(productAttributeValue.priceAdjustment)})`
        : ""
        }`}
      <input
        onChange={(e) => {
          const res = handleRadioButon(
            productAttributeValue,
            optionDisplayOrder,
            attributeType
          );
          if (!res) {
            e.target.checked = false;
          }
        }}
        type="radio"
        value={productAttributeValue.id}
        name={`${attributeName}${optionId}`}
        defaultChecked={isInCart || productAttributeValue.isPreSelected}
      />
      <span className="checkCircle"></span>
      <span className="checkmark"></span>
    </label>
  );
};

export default Radio2;
