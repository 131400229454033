import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button, Grid, Paper, TextField } from "@material-ui/core";

import { selectInformation } from "../../Stores/informationSlice";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import Loading from "../Loading/Loading";

import { ContactUsStyles } from "./ContactUsStyles";
import { useEffect } from "react";

const ContactUs = () => {
  const classes = ContactUsStyles();
  const { t } = useTranslation();
  const information = useSelector(selectInformation);
  function createCenterControl() {
    const controlButton = document.createElement("button");

    // Set CSS for the control.
    controlButton.style.backgroundColor = "#fff";
    controlButton.style.border = "2px solid #fff";
    controlButton.style.borderRadius = "3px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.style.color = "rgb(25,25,25)";
    controlButton.style.cursor = "pointer";
    controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
    controlButton.style.fontSize = "16px";
    controlButton.style.lineHeight = "38px";
    controlButton.style.margin = "8px 0 22px";
    controlButton.style.padding = "0 5px";
    controlButton.style.textAlign = "center";

    controlButton.textContent = "Direction";
    controlButton.title = "Click to recenter the map";
    controlButton.type = "button";

    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener("click", () => {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${information?.latitude},${information?.longitude}`,
        "_blank",
        "noopener,noreferrer"
      );
    });
    controlButton.addEventListener("touchstart", () => {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${information?.latitude},${information?.longitude}`,
        "_blank",
        "noopener,noreferrer"
      );
    });
    return controlButton;
  }
  const renderMarkers = (map: any, maps: any) => {
    map.overviewMapControl = true;
    let marker = new maps.Marker({
      position: {
        lat: information?.latitude,
        lng: information?.longitude,
      },
      map,
      title: "go for direction",
    });
    marker.addListener("click", () => {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${information?.latitude},${information?.longitude}`,
        "_blank",
        "noopener,noreferrer"
      );
    });
    return marker;
  };
  if (!information) return <Loading />;
  return (
    <>
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Grid container justifyContent="center" className={classes.contactUs}>
        {information.openingTime && (
          <Grid item xs={12} md={6} className="gridItem">
            <Paper className="paper">
              <h4>{t("onlineOpeningTimes")}</h4>
              <div
                dangerouslySetInnerHTML={{ __html: information.openingTime }}
              />
            </Paper>
          </Grid>
        )}

        {information.longitude && information.latitude && (
          <Grid item xs={12} md={6} className="gridItem">
            <Paper className="map">
              <GoogleMapReact
                // layerTypes={}
                bootstrapURLKeys={{
                  key: "AIzaSyDox6EsX_YHjshqbtw2zyeCf4ASLrDx-8Q",
                }}
                defaultCenter={{
                  lat: information.latitude,
                  lng: information.longitude,
                }}
                defaultZoom={14}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  renderMarkers(map, maps);
                  // Create the DIV to hold the control.
                  const centerControlDiv = document.createElement("div");
                  // Create the control.
                  const centerControl = createCenterControl();
                  // Append the control to the DIV.
                  centerControlDiv.appendChild(centerControl);

                  map.controls[maps.ControlPosition.TOP_CENTER].push(
                    centerControlDiv
                  );
                }}
              />
            </Paper>
          </Grid>
        )}

        {information.contactUs && (
          <Grid item xs={12} md={6} className="gridItem">
            <Paper className="paper">
              <h4>{t("contactUs")}</h4>
              <div
                dangerouslySetInnerHTML={{ __html: information.contactUs }}
              />
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} md={6} className="gridItem">
          <Paper className="ticketForm">
            <TextField label={t("email")} variant="outlined" color="primary" />
            <TextField
              label={t("yourMessage")}
              multiline
              rows={4}
              variant="outlined"
              color="primary"
            />
            <Grid container justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary">
                {t("submit")}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUs;
