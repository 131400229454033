import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const AnalogueClockStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .clock": {
        width: 260,
        margin: "0 auto",
        borderRadius: "100%",
        background: "#ffffff",
        fontFamily: "Montserrat",
        border: "5px solid white",
        boxShadow: "inset 2px 3px 8px 0 rgba(0, 0, 0, 0.1)",
      },

      "& .wrap": {
        overflow: "hidden",
        position: "relative",
        width: 250,
        height: 250,
        borderRadius: "100%",
      },

      "& h6": {
        position: "absolute",
        width: "fit-content",
        fontFamily: "sans-serif",
        fontWeight: 900,
        color: "#004065",
      },
      "& .twelve": {
        top: 0,
        right: "47%",
      },
      "& .three": {
        top: "47%",
        right: 5,
      },
      "& .six": {
        right: "47%",
        bottom: 0,
      },
      "& .nine": {
        top: "47%",
        left: 5,
      },

      "& .minute, .hour": {
        position: "absolute",
        height: 80,
        width: 6,
        margin: "auto",
        top: "-32%",
        left: 0,
        bottom: 0,
        right: 0,
        background: "#004065",
        transformOrigin: "bottom center",
        transform: "rotate(0deg)",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
        zIndex: 1,
      },

      "& .minute": {
        position: "absolute",
        height: 100,
        width: 4,
        top: "-38%",
        left: 0,
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
        transform: "rotate(90deg)",
      },

      "& .dot": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 12,
        height: 12,
        borderRadius: 100,
        background: "white",
        border: "2px solid #004065",
        margin: "auto",
        zIndex: 1,
      },
    },
  })
);
