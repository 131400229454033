import { DialogContent, Dialog, DialogProps } from "@material-ui/core";
import React, { useState } from "react";
interface IProps {
  temporaryShopCloseText: string;
}
const TemporaryShopClose: React.FC<IProps> = ({ temporaryShopCloseText }) => {
  const scroll: DialogProps["scroll"] = "paper";
  const [deliveryCollectionModal, setDeliveryCollectionModal] = useState(true);
  const handleShow = () => {
    setDeliveryCollectionModal(false);
  };
  return (
    <Dialog
      open={deliveryCollectionModal}
      onClose={() => handleShow()}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogContent
        style={{ backgroundColor: "#C00A28" }}
        dividers={scroll === "paper"}
      >
        <div dangerouslySetInnerHTML={{ __html: temporaryShopCloseText }}></div>
      </DialogContent>
    </Dialog>
  );
};

export default TemporaryShopClose;
