import {
  Action,
  combineReducers,
  configureStore,
  createAsyncThunk,
  AsyncThunkPayloadCreator,
  Dispatch,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import redisCartReducer from "./redisCartSlice";
import themeReducer from "./themeSlice";
import informationReducer from "./informationSlice";
import deliveryReducer from "./deliverySlice";
import allowDeliveryReducer from "./alowDelivery";
import commonReducer from "./commonSlice";
import orderEntryCartReducer from "./orderEntryCartSlice";
import redisOrderEntryCartReducer from "./redisOrderEntryCartSlice";
import discountsReducer from "./discountsSlice";
import orderEntryHeaderInfoReducer from "./orderEntryHeaderInfoSlice";
import thunk, { ThunkAction } from "redux-thunk";
import { ICart } from "../Models/Cart/ICart";
// import { ICart } from "../Models/cart";

const reducer = combineReducers({
  user: userReducer,
  redisCart: redisCartReducer,
  theme: themeReducer,
  information: informationReducer,
  delivery: deliveryReducer,
  allowDelivery: allowDeliveryReducer,
  common: commonReducer,
  orderEntryCart: orderEntryCartReducer,
  redisOrderEntryCart: redisOrderEntryCartReducer,
  discounts: discountsReducer,
  orderEntryHeaderInfo: orderEntryHeaderInfoReducer,
});

const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [...getDefaultMiddleware(), thunk],
});
interface ICartState {
  redisCartReducer: {
    data: ICart;
  };
}
export type RootState = ReturnType<typeof reducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;
declare module "@reduxjs/toolkit" {
  type AsyncThunkConfig = {
    state?: unknown;
    dispatch?: Dispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
  };

  function createAsyncThunk<
    Returned,
    ThunkArg = void,
    ThunkApiConfig extends AsyncThunkConfig = { state: ICartState }
  >(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<
      Returned,
      ThunkArg,
      ThunkApiConfig
    >,
    options?: any
  ): any;
}

export default store;
