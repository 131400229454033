import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const InformationPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",
      margin: "1rem",

      "@media (max-width: 1024px)": {
        "& img": {
          width: "100% !important",
          height: "auto !important",
        },
      },
    },
  })
);
