import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { PayloadAction } from "@reduxjs/toolkit";

export const slice = createGenericSlice({
  name: "allowDelivery",
  initialState: {
    status: "loading",
    data: false,
  } as GenericState<boolean>,
  reducers: {
    setAllowDelivery: (state, action: PayloadAction<boolean>) => {
      state.status = "finished";
      state.data = action.payload;
    },
  },
});
export const selectAllowDelivery = (state: {
  allowDelivery: { data: boolean };
}) => state.allowDelivery.data;
export const { setAllowDelivery } = slice.actions;

export default slice.reducer;
