import { PayloadAction } from "@reduxjs/toolkit";
import { Slide, toast } from "react-toastify";

import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { ICart } from "../Models/Cart/ICart";
import { IBaseItemCart } from "../Models/Cart/IBaseItemCart";

interface ILocalStorageCart {
  expirationDate: Date;
  cart: ICart;
}

export const slice = createGenericSlice({
  name: "redisOrderEntryCart",
  initialState: {
    status: "loading",
    data: getRedisOrderEntryCart("redisOrderEntryCart"),
  } as GenericState<ICart>,
  reducers: {
    addToCart: (state, action: PayloadAction<ICart>) => {
      state.data = { ...action.payload };
      if (state.data) {
        setRedisOrderEntryCart("redisOrderEntryCart", state.data, 24 * 60);
        if (action.payload.responseText)
          toast.success(action.payload.responseText, {
            autoClose: 1000,
            transition: Slide,
          });
      }
    },
    reduceFromCart: (state, action: PayloadAction<IBaseItemCart>) => {
      let cart = state.data ? { ...state.data } : undefined;
      if (cart && cart.userCart?.carts?.length > 0) {
        let findItemCart = cart.userCart.carts.find(
          (item) =>
            item.productId === action.payload.id &&
            item.duplicateNum === action.payload.duplicateNum
        );
        if (findItemCart) {
          if (findItemCart.quantity < 2) {
            cart.userCart.carts = cart.userCart.carts.filter((item) => {
              return (
                item.productId !== action.payload.id &&
                item.duplicateNum === action.payload.duplicateNum
              );
            });
          } else {
            findItemCart.quantity--;
          }
          state.data = { ...cart };
          if (state.data) {
            setRedisOrderEntryCart("redisOrderEntryCart", state.data, 24 * 60);
          }
        }
      }
    },
    addMoreToCart: (state, action: PayloadAction<IBaseItemCart>) => {
      let cart = state.data ? { ...state.data } : undefined;
      if (cart && cart.userCart?.carts?.length > 0) {
        let findItemCart = cart.userCart.carts.find(
          (item) =>
            item.productId === action.payload.id &&
            item.duplicateNum === action.payload.duplicateNum
        );
        if (findItemCart) {
          findItemCart.quantity++;
          state.data = { ...cart };
          if (state.data) {
            setRedisOrderEntryCart("redisOrderEntryCart", state.data, 24 * 60);
          }
        }
      }
    },
    emptyCart: (state) => {
      state.data = undefined;
      window.localStorage.removeItem("redisOrderEntryCart");
    },
  },
});

function getRedisOrderEntryCart(key: string) {
  let stringValue = window.localStorage.getItem(key);
  if (stringValue) {
    let value: ILocalStorageCart = JSON.parse(stringValue);
    let expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.cart;
    } else {
      window.localStorage.removeItem(key);
    }
  }
  return null;
}

function setRedisOrderEntryCart(
  key: string,
  value: ICart,
  expirationInMin = 10
) {
  window.localStorage.removeItem(key);
  let expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  value.userCart.carts = value.userCart.carts.sort(
    (a, b) => b.displayOrder - a.displayOrder
  );
  let newValue: ILocalStorageCart = {
    cart: value,
    expirationDate: expirationDate,
  };
  window.localStorage.setItem(key, JSON.stringify(newValue));
}

export const selectCart = (state: { redisOrderEntryCart: { data: ICart } }) =>
  state.redisOrderEntryCart.data;

export const { addToCart, reduceFromCart, addMoreToCart, emptyCart } =
  slice.actions;
export default slice.reducer;
