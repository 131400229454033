import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { PayloadAction } from "@reduxjs/toolkit";
import { ITheme } from "../Models/theme";

export const slice = createGenericSlice({
  name: "theme",
  initialState: {
    status: "loading",
    data: {
      name: "lightTheme",
    },
  } as GenericState<ITheme>,
  reducers: {
    changeTheme: (state, action: PayloadAction<ITheme>) => {
      state.status = "finished";
      state.data = action.payload;
    },
  },
});
export const selectTheme = (state: { theme: { data: ITheme } }) =>
  state.theme.data.name;
export const { changeTheme } = slice.actions;

export default slice.reducer;
