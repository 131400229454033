export interface IUser {
  firstName: string;
  lastName: string;
  token: string;
  roles: string[];
  guest: boolean;
  tableOrder: boolean;
}

export interface IUserSession {
  date: Date;
  user: IUser;
}

export interface ILogin {
  userName: string;
  password: string;
  rememberMe: boolean;
}

export interface IRegister {
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  tell: string;
}

export interface IJwt {
  unique_name: string;
  nameId: string;
  role: string[];
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
}
export interface IUserDetail {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  phoneNumber: string;
  rolesId: string[];
  roles: string[];
}

export interface IUserList {
  id: string;
  fullName: string;
  email: string;
  userName: string;
  registerDate?: Date;
  roles: string[];
}

export interface IChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface IResetPassword {
  userName: string;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface IUserEdit {
  id?: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  phoneNumber: string;
  rolesId: string[];
  newPassword?: string;
}

export function UserDetailToUSerEdit(user: IUserDetail): IUserEdit {
  return {
    id: user.id,
    userName: user.userName,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    rolesId: user.rolesId,
    phoneNumber: user.phoneNumber,
  };
}
