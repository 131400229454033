import React from "react";
import { useSelector } from "react-redux";

import { Grid, Paper } from "@material-ui/core";

import { InformationPageStyles } from "./InformationPageStyles";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import { selectInformation } from "../../Stores/informationSlice";

interface IProps {
  htmlValue: string;
}

const InformationPage: React.FC<IProps> = ({ htmlValue }) => {
  const classes = InformationPageStyles();
  const information = useSelector(selectInformation);

  return (
    <Grid container>
      <Grid item xs={12}>
        {information.themeDisplay !== "1" && <BackgroundImage />}
        <Paper className={classes.root}>
          <div dangerouslySetInnerHTML={{ __html: htmlValue }}></div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InformationPage;
