export function GetStoreId(): string | null {
  let stringValue = window.localStorage.getItem("storeGuid");
  if (stringValue !== null) {
    let value: ILocalStorageStore = JSON.parse(stringValue);
    let expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.storeId;
    } else {
      window.localStorage.removeItem("storeGuid");
    }
  }
  return null;
}
export function SetStoreId(value: string, expirationInHour = 1) {
  window.localStorage.removeItem("storeGuid");

  let expirationDate = new Date(
    new Date().getTime() + 60000 * 60000 * expirationInHour
  );
  let newValue: ILocalStorageStore = {
    storeId: value,
    expirationDate: expirationDate,
  };
  window.localStorage.setItem("storeGuid", JSON.stringify(newValue));
}

interface ILocalStorageStore {
  expirationDate: Date;
  storeId: string;
}
