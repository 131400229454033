import React from "react";
import { FieldRenderProps } from "react-final-form";
import { SelectList } from "../Models/selectList";
import { TextField, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
interface IProps extends FieldRenderProps<any, HTMLElement> {
  options: SelectList<any>[];
}
const SelectInput: React.FC<IProps> = ({
  input,
  options,
  label,
  meta: { touched, error },
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      select
      variant="outlined"
      label={label}
      value={input.value}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value) {
          const val = event.target.value as string;
          if (isNumeric(val)) {
            input.onChange(parseInt(val));
          } else input.onChange(val);
        }
      }}
      helperText={error}
      error={touched && error}
      size="small"
      fullWidth
      margin="normal"
    >
      <MenuItem selected value={0}>
        <em>
          {t("selectA")}&nbsp; {label}
        </em>
      </MenuItem>
      {options.map((option: SelectList<any>) => (
        <MenuItem key={option.id} value={option.id}>
          {t(option.text)}
        </MenuItem>
      ))}
    </TextField>
  );
};
function isNumeric(value: any) {
  return /^-{0,1}\d+$/.test(value);
}
export default SelectInput;
