import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../Modal/CustomModal";

interface IProps {
  email: string;
  setEmail(email: string): void;
  resetPassword(email: string): void;
  passResetModal: boolean;
  setPassResetModal(open: boolean): void;
}
const PasswordForgot: React.FC<IProps> = ({
  email,
  setEmail,
  resetPassword,
  passResetModal,
  setPassResetModal,
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      title={t("forgotPassword")}
      description={t("enterYourIdToResetYourPassword")}
      body={
        <TextField
          label={t("emailAddress")}
          type="email"
          variant="outlined"
          margin="dense"
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
        />
      }
      action={
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            resetPassword(email);
            setTimeout(function () {
              setPassResetModal(false);
            }, 2000);
          }}
        >
          {t("submit")}
        </Button>
      }
      open={passResetModal}
      handleShow={() => setPassResetModal(false)}
      fullWidth={true}
      maxWidth="xs"
    />
  );
};

export default PasswordForgot;
