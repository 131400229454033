import React from "react";

import { AxiosResponse } from "axios";

interface IProps {
  error: AxiosResponse;
  text?: string;
}
const ErrorMessage: React.FC<IProps> = ({ error, text }) => {
  return (
    error && (
      <div
        style={{
          backgroundColor: "#fff6f6",
          padding: "1rem",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <h6 style={{ color: "#9f3a38" }}>{text}</h6>
        {error.data &&
          Object.keys(error.data.errors).length > 0 &&
          Object.values(error.data.errors)
            .flat()
            .map((err: any, i) => {
              if (err.Description) {
                return (
                  <li
                    key={`error${i}`}
                    style={{ color: "#9f3a38" }}
                    // align="center"
                  >
                    {err.Description}
                  </li>
                );
              } else if (typeof err === "string") {
                return (
                  <li
                    key={`error${i}`}
                    style={{ color: "#9f3a38" }}
                    // align="center"
                  >
                    {err}
                  </li>
                );
              } else {
                return null;
              }
            })}
      </div>
    )
  );
};

export default ErrorMessage;
