import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const TimePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    timePickerContainer: {
      display: "flex",
      alignItems: "center",
      width: "max-content",
      "& *": {
        borderRadius: 5,
      },
      "& .react-datepicker": {
        border: "none",
        borderRadius: 5,
        boxShadow:
          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      },
      "& .react-datepicker-popper": {
        zIndex: 2,
      },
      "& .react-datepicker__triangle": {
        display: "none",
      },
      "& .react-datepicker__header": {
        display: "none",
      },
      "& .react-datepicker__time": {
        width: 120,
      },
      "& .react-datepicker__time-box": {
        width: "120px !important",
      },
      "& .react-datepicker__time-list-item": {
        fontSize: "1rem",
        textAlign: "center",
      },
      "& ::-webkit-scrollbar": {
        width: 7,
      },
      /* Track */
      "& ::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      /* Handle */
      "& ::-webkit-scrollbar-thumb": {
        background: "#999",
      },
      /* Handle on hover */
      "& ::-webkit-scrollbar-thumb:hover": {
        background: "#777",
      },
      "& .react-datepicker__time-list-item--disabled": {
        display: "none",
      },
    },
    timePicker: {
      width: 120,
      height: 40,
      paddingLeft: 10,
      borderRadius: 5,
      border: "1px solid #C3C2C0",
      cursor: "pointer",
      color: "#000",

      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      "&:focus": {
        outlineWidth: 0,
      },
      "&.react-datepicker-ignore-onclickoutside": {
        border: "2px solid " + theme.palette.primary.main,
      },
      "&:disabled:hover": {
        borderColor: "#C3C2C0",
      },
    },
    timePickerIcon: {
      position: "absolute",
      top: 8,
      right: 6,
      color: "#716F6F",
      pointerEvents: "none",
    },
  })
);
