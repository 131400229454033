import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IProductAttributeValue } from "../../../Models/productAttributeValue";
import { selectMoney } from "../../../Stores/informationSlice";
import { Radio1Styles } from "./Radio1Styles";

interface IProps {
  productAttributeValue: IProductAttributeValue;
  attributeName: string;
  optionId: string;
  handleRadioButon(
    value: IProductAttributeValue,
    optionDisplayOrder: number,
    attributeType: string
  ): boolean;
  attributeType: string;
  isSelected: boolean;
  isEditMode: boolean;
  optionDisplayOrder: number;
}

const Radio1: React.FC<IProps> = ({
  productAttributeValue,
  attributeName,
  optionId,
  handleRadioButon,
  attributeType,
  isSelected,
  optionDisplayOrder,
}) => {
  const money = useSelector(selectMoney);
  const classes = Radio1Styles();

  return (
    <label className={classes.ProductSizeStyle + " radioButton"}>
      {`${productAttributeValue.name} ${
        productAttributeValue.priceAdjustment > 0
          ? `(${money(productAttributeValue.priceAdjustment)})`
          : ""
      }`}
      <input
        onChange={(e) => {
          const res = handleRadioButon(
            productAttributeValue,
            optionDisplayOrder,
            attributeType
          );
          if (!res) {
            e.target.checked = false;
          }
        }}
        type="radio"
        value={productAttributeValue.id}
        name={`${attributeName}${optionId}`}
        checked={isSelected}
      />
      <span className="checkCircle"></span>
      <span className="checkmark"></span>
    </label>
  );
};

export default Radio1;
