import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const PaymentSenseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& iframe": {
        width: "100%",
      },
      "& .connect-container": {
        marginTop: 80,
      },
      "& .connect-btn": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: "100% !important",
        height:"unset !important",
        marginRight: "auto !important",
        marginLeft: "auto !important",
        textAlign: "center"
      },
    },
  })
);
