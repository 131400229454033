import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import { Button, Grid } from "@material-ui/core";

import { combineValidators, isRequired } from "revalidate";

import { IAddAddress } from "../../../../Models/guest";
import ErrorMessage from "../../../../utils/ErrorMessage";
import TextInput from "../../../../utils/TextInput";
import CustomModal from "../../../Modal/CustomModal";

interface IProps {
  addAddressModal: boolean;
  setAddAddressModal(status: boolean): void;
  addAddress: IAddAddress;
  handleSubmit(addAddress: IAddAddress): void;
}

const AddAddress: React.FC<IProps> = ({
  addAddressModal,
  setAddAddressModal,
  addAddress,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const validate = combineValidators({
    street: isRequired({ message: t("required") }),
    town: isRequired({ message: t("required") }),
    area: isRequired({ message: t("required") }),
    address1: isRequired({ message: t("required") }),
  });

  return (
    <CustomModal
      title={t("addAddress")}
      open={addAddressModal}
      handleShow={() => setAddAddressModal(true)}
      description={t("fillThisFormToAddNewAddress")}
      body={
        <Form
          validate={validate}
          initialValues={addAddress}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          keepDirtyOnReinitialize
          render={({
            handleSubmit,
            invalid,
            submitError,
            dirtySinceLastSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && !dirtySinceLastSubmit && (
                <ErrorMessage text="Error(s)" error={submitError} />
              )}
              <Field
                label={t("street")}
                name="street"
                component={TextInput}
                placeholder={t("street")}
                required
              />
              <Field
                label={t("town")}
                name="town"
                component={TextInput}
                placeholder={t("town")}
                required
              />
              <Field
                label={t("area")}
                name="area"
                component={TextInput}
                placeholder={t("area")}
                required
              />
              <Field
                label={t("addressLine1")}
                name="address1"
                component={TextInput}
                placeholder={t("addressLine1")}
                required
              />
              <Grid container justifyContent="flex-end">
                <Button
                  type="button"
                  onClick={() => setAddAddressModal(false)}
                  variant="contained"
                  color="secondary"
                  style={{ margin: 5 }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={invalid}
                  color="primary"
                  style={{ margin: 5 }}
                >
                  {t("submit")}
                </Button>
              </Grid>
            </form>
          )}
        />
      }
      action={null}
      fullWidth
      maxWidth="sm"
    />
  );
};

export default AddAddress;
