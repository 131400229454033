import jMoment from "moment-jalaali";
import React from "react";
import JalaliUtils from "@date-io/jalaali";
import MomentUtils from "@date-io/moment";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { selectInformation } from "../../../Stores/informationSlice";
import { useSelector } from "react-redux";

interface IProps {
  label: string;
  date: Date;
  setDate(date: Date): void;
  noPast?: true;
}
const DatePicker: React.FC<IProps> = ({ label, date, setDate, noPast }) => {
  const information = useSelector(selectInformation);
  if (information.langDisplay === "fa-IR")
    jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

  return (
    <MuiPickersUtilsProvider
      utils={information.langDisplay === "fa-IR" ? JalaliUtils : MomentUtils}
      locale={information.langDisplay === "fa-IR" ? "fa" : "en-GB"}
    >
      <KeyboardDatePicker
        label={label}
        disablePast={noPast}
        disableToolbar
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        variant="inline"
        inputVariant="outlined"
        size="small"
        margin="normal"
        autoOk
        labelFunc={
          information.langDisplay === "fa-IR"
            ? (date) => (date ? date.format("jYYYY/jMM/jDD") : "")
            : (date) => (date ? date.format("YYYY/MM/DD") : "")
        }
        value={date}
        onChange={(event) => {
          if (event)
            setDate(new Date(event?.locale("en-GB").format("YYYY/MM/DD")));
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
