import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { PayloadAction } from "@reduxjs/toolkit";
import { IDeliveryCollection } from "../Models/deliveryCharge";

export const slice = createGenericSlice({
  name: "delivery",
  initialState: {
    status: "loading",
    data: {
      delivery: false,
      postCode: "",
      deliveryCharge: 0,
      minimumOrder: 0,
    },
  } as GenericState<IDeliveryCollection>,
  reducers: {
    setDelivery: (state, action: PayloadAction<IDeliveryCollection>) => {
      state.status = "finished";
      state.data = action.payload;
      if (state.data) {
        window.localStorage.setItem("postCode", JSON.stringify(state.data));
      }
    },
  },
});
export const selectDelivery = (state: {
  delivery: { data: IDeliveryCollection };
}) => state.delivery.data;
export const { setDelivery } = slice.actions;

export default slice.reducer;
