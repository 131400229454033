import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { IDeliveryTime } from "../../Models/deliveryTime";
import { DayOfWeekString } from "../../Models/enum";
import { IOpenTimes } from "../../Models/hangFire";
import agent from "../../Services/agent";
import {
  selectInformation,
  dateFormatWithWeekdayWithoutTime,
  dateFormatWithOnlyTime,
} from "../../Stores/informationSlice";
import AnalogueClock from "../Common/AnalogueClock/AnalogueClock";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import Loading from "../Loading/Loading";
import DeliveryOpeningTimes from "./DeliveryOpeningTimes/DeliveryOpeningTimes";

import { OpeningTimesStyles } from "./OpeningTimesStyles";

export default function OpeningTimes() {
  const classes = OpeningTimesStyles();
  const { t } = useTranslation();
  const [openingTimes, setOpeningTimes] = useState<IOpenTimes[]>([]);
  const [deliveryOpeningTimes, setDeliveryOpeningTimes] = useState<
    IDeliveryTime[]
  >([]);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [todayStatus, setTodayStatus] = useState<IOpenTimes>();
  const information = useSelector(selectInformation);
  const [h, setH] = useState("0");
  const [m, setM] = useState("0");
  const dateFormatterWithWeekdayWithoutTime = useSelector(
    dateFormatWithWeekdayWithoutTime
  );
  const dateFormatterWithOnlyTime = useSelector(dateFormatWithOnlyTime);

  const setAnalogueClockTime = (todayStatus: IOpenTimes | undefined) => {
    if (todayStatus && todayStatus.items && todayStatus.items.length > 0) {
      const val = todayStatus.items[0];
      if (val) {
        setH(val.hour.toString());
        setM(val.minute.toString());
      }
    }
  };

  const nowTime = useCallback(() => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
    };
    const t = new Intl.DateTimeFormat("en-US", options).format(new Date());
    setTime(t);
  }, []);

  const getDate = () => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const d = new Intl.DateTimeFormat("en-GB", options).format(new Date());
    setDate(d);
  };

  const getToday = (res: IOpenTimes[]) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
    };
    const today = new Intl.DateTimeFormat("en-GB", options).format(new Date());
    const s = res.find((a) => a.dayOfWeek === today);

    setTodayStatus(s);
    setAnalogueClockTime(s);
  };

  const getOpeningTimes = async () => {
    await agent.Setting.getOpenTimes().then((res) => {
      setOpeningTimes(res);
      getToday(res);
    });
  };

  const getDeliveryOpeningTimes = async () => {
    await agent.DeliveryTimes.List(100, 0).then((res) => {
      const days = Object.values(DayOfWeekString);
      const openDays = res.value.map((item) => item.dayOfWeek);
      const weekDays: IDeliveryTime[] = [];
      for (let i = 0; i <= 6; i++) {
        weekDays.push({
          id: days[i],
          dayOfWeek: days[i],
          start: "00:00",
          end: "00:00",
        });
      }
      const closeDays = weekDays.filter(function (item) {
        return openDays.indexOf(item.dayOfWeek) === -1;
      });

      const deliveryDays = closeDays.concat(res.value);

      setDeliveryOpeningTimes(deliveryDays);
    });
  };
  const breakElement = (index: number, length: number) => {
    if ((index + 1) % 2 === 0 && length > index + 1) {
      return <br></br>;
    } else return null;
  };
  useEffect(() => {
    nowTime();
    setInterval(() => {
      nowTime();
    }, 1000 * 60);
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowTime]);

  useEffect(() => {
    getOpeningTimes();
    getDeliveryOpeningTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!todayStatus) return <Loading />;
  return (
    <>
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Grid container justifyContent="center" className={classes.root}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={10}
          xl={9}
          container
          component={Paper}
          className={classes.paper}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={6}
            xl={6}
            className={classes.tableContainer}
          >
            <h3>{t("onlineOrderingOpeningTimes")}</h3>
            <TableContainer className={classes.table} component={Paper}>
              <Table>
                <TableBody>
                  {openingTimes.map((i) => (
                    <TableRow key={i.dayOfWeek}>
                      <TableCell>{t(i.dayOfWeek.toLowerCase())}</TableCell>
                      <TableCell align="right">
                        {i.isClose ? (
                          <span className="closed">
                            {t("onlineOrderClosed")}
                          </span>
                        ) : (
                          i.items.map((item, index) => (
                            <span key={index}>
                              {`${
                                item.hour < 10 ? `0${item.hour}` : item.hour
                              }:${
                                item.minute < 10
                                  ? `0${item.minute}`
                                  : item.minute
                              }`}
                              {breakElement(index, i.items.length)}
                              {index % 2 === 0 && ` ${t("to")} `}
                            </span>
                          ))
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {information.minimumDeliveryTime > 0 && (
              <Grid item xs={12} className={classes.tableContainer}>
                <h3>{t("deliveryOpeningTimes")}</h3>
                <DeliveryOpeningTimes
                  deliveryOpeningTimes={deliveryOpeningTimes}
                  styleClass={classes.table}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={6}
            xl={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.timeContainer}
          >
            {information.langDisplay === "fa-IR" ? (
              <Paper className={classes.time}>
                <p>{t("today")}:</p>
                <h6>
                  {dateFormatterWithWeekdayWithoutTime.format(new Date())}
                </h6>
                <div className="flex">
                  <h2>
                    {dateFormatterWithOnlyTime.format(new Date()).split(" ", 1)}
                  </h2>
                  <h6 style={{ marginRight: "10px" }}>
                    {dateFormatterWithOnlyTime.format(new Date()).slice(5)}
                  </h6>
                </div>
              </Paper>
            ) : (
              <Paper className={classes.time}>
                <p>{t("today")}:</p>
                <h6>{date}</h6>
                <div className="flex">
                  <h2>{time.split(" ", 1)}</h2>
                  <h6>{time.slice(5, 8)}</h6>
                </div>
              </Paper>
            )}
            <div className={classes.today}>
              {todayStatus?.isClose ? (
                <h4 className="closed">{t("todayOnlineOrderingIsClosed")}</h4>
              ) : (
                <>
                  <h4>{t("onlineOrderIsOpenToday")}:</h4>
                  <AnalogueClock hours={h} minutes={m} />
                  <p>
                    {t(
                      "pleaseNoteLastOnlineOrdersAre_30MinutesBeforeClosingTime"
                    )}
                  </p>
                </>
              )}
              {information.preOrder && <p>{t("youCanSubmitPreOrder")}</p>}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
